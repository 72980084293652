import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "components/layout";
import { rhythm, scale } from "utils/typography";
import styled from "@emotion/styled";
import { mq } from "utils/helper";
import SEO from "components/seo";
import { jsx } from "@emotion/core";
import { colors } from "../style/theme";
import Updates from "components/homepage/homepage-updates";

const H1 = styled.h1({
  ...scale(2.5),
  fontWeight: "100",
  color: colors.primary,
  marginBottom: 0,
  [mq[1]]: {
    ...scale(2)
  }
});

function NotFoundPage({ data }) {
  const siteTitle = data.site.siteMetadata.title;
  return (
    <div>
      <Layout title={siteTitle}>
        <SEO title="404: Not Found" />
        <div
          css={{
            background:
              "linear-gradient(180deg, #000000 0%, #012A2A 55.42%, #000000 100%);",
            paddingBottom: "5rem"
          }}
        >
          <div
            css={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: rhythm(4)
            }}
          >
            <H1
              css={{
                marginRight: rhythm(1),
                [mq[1]]: {
                  marginLeft: rhythm(1)
                }
              }}
            >
              404
            </H1>
          </div>
          <div
            css={{ color: colors.light, textAlign: "center", fontSize: "24px" }}
          >
            <p>Nothing found here.</p>
            <p>But you can still browse our recent updates</p>
          </div>
          <Updates />
        </div>
      </Layout>
    </div>
  );
}

NotFoundPage.propTypes = {
  data: PropTypes.object
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
